import React from 'react'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import SVG from 'react-inlinesvg'

export const CloseIcon = (props) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 0.5C10.871 0.5 14 3.629 14 7.5C14 11.371 10.871 14.5 7 14.5C3.129 14.5 0 11.371 0 7.5C0 3.629 3.129 0.5 7 0.5ZM9.513 4L7 6.513L4.487 4L3.5 4.987L6.013 7.5L3.5 10.013L4.487 11L7 8.487L9.513 11L10.5 10.013L7.987 7.5L10.5 4.987L9.513 4Z"
        fill="white"
      />
    </svg>
  )
}

export const ArrowRight = (props) => {
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.625 9.51514L11 5.5L6.625 1.48486M11 5.5L1 5.5L11 5.5Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const Ov = (props) => {
  return (
    <svg
      width="12"
      height="22"
      viewBox="0 0 12 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.20105 7.29443C5.20105 7.98699 4.57008 8.72814 3.75194 8.72814V20.4182C3.75194 22.0781 1.49017 22.0781 1.49017 20.4182V8.72814C0.693587 8.72814 0 8.11462 0 7.19554V0.76198C0 0.202183 0.8089 0.180968 0.8089 0.783879V5.53941H1.4864V0.740081C1.4864 0.225793 2.26588 0.192944 2.26588 0.76198V5.53976H2.96597V0.746924C2.96597 0.209027 3.72217 0.18747 3.72217 0.768139V5.53976H4.41097V0.746924C4.41097 0.214501 5.20105 0.193629 5.20105 0.768139V7.29477V7.29443ZM11.2346 2.25762V20.413C11.2346 22.036 8.96804 22.012 8.96804 20.413V13.2092H7.76154V2.25762C7.76154 -0.291916 11.2349 -0.291916 11.2349 2.25762H11.2346Z"
        fill="black"
      />
    </svg>
  )
}

const Cafetaria = (props) => {
  return (
    <svg
      width="12"
      height="22"
      viewBox="0 0 12 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.20105 7.29443C5.20105 7.98699 4.57008 8.72814 3.75194 8.72814V20.4182C3.75194 22.0781 1.49017 22.0781 1.49017 20.4182V8.72814C0.693587 8.72814 0 8.11462 0 7.19554V0.76198C0 0.202183 0.8089 0.180968 0.8089 0.783879V5.53941H1.4864V0.740081C1.4864 0.225793 2.26588 0.192944 2.26588 0.76198V5.53976H2.96597V0.746924C2.96597 0.209027 3.72217 0.18747 3.72217 0.768139V5.53976H4.41097V0.746924C4.41097 0.214501 5.20105 0.193629 5.20105 0.768139V7.29477V7.29443ZM11.2346 2.25762V20.413C11.2346 22.036 8.96804 22.012 8.96804 20.413V13.2092H7.76154V2.25762C7.76154 -0.291916 11.2349 -0.291916 11.2349 2.25762H11.2346Z"
        fill="black"
      />
    </svg>
  )
}

const Parking = (props) => {
  return (
    <svg
      width="13"
      height="18"
      viewBox="0 0 13 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 0H0V18H4V12H7C10.31 12 13 9.31 13 6C13 2.69 10.31 0 7 0ZM7.2 8H4V4H7.2C8.3 4 9.2 4.9 9.2 6C9.2 7.1 8.3 8 7.2 8Z"
        fill="black"
      />
    </svg>
  )
}

const Shower = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 12V13C19 14.91 17.93 16.57 16.35 17.41L17 20H15L14.5 18H5.5L5 20H3L3.65 17.41C2.84919 16.9852 2.17931 16.3501 1.71239 15.5731C1.24548 14.7961 0.999186 13.9065 1 13V12H0V10H18V3C18 2.73478 17.8946 2.48043 17.7071 2.29289C17.5196 2.10536 17.2652 2 17 2C16.5 2 16.12 2.34 16 2.79C16.63 3.33 17 4.13 17 5H11C11 4.20435 11.3161 3.44129 11.8787 2.87868C12.4413 2.31607 13.2044 2 14 2H14.17C14.58 0.84 15.69 0 17 0C17.7956 0 18.5587 0.316071 19.1213 0.87868C19.6839 1.44129 20 2.20435 20 3V12H19ZM17 12H3V13C3 13.7956 3.31607 14.5587 3.87868 15.1213C4.44129 15.6839 5.20435 16 6 16H14C14.7956 16 15.5587 15.6839 16.1213 15.1213C16.6839 14.5587 17 13.7956 17 13V12Z"
        fill="black"
      />
    </svg>
  )
}

const Shirt = (props) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 1L19 3V8H16V16C16 16.2652 15.8946 16.5196 15.7071 16.7071C15.5196 16.8946 15.2652 17 15 17H5C4.73478 17 4.48043 16.8946 4.29289 16.7071C4.10536 16.5196 4 16.2652 4 16V8H1V3L7 1C7 1.79565 7.31607 2.55871 7.87868 3.12132C8.44129 3.68393 9.20435 4 10 4C10.7956 4 11.5587 3.68393 12.1213 3.12132C12.6839 2.55871 13 1.79565 13 1Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const Pin = (props) => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V11C0 11.7956 0.316071 12.5587 0.87868 13.1213C1.44129 13.6839 2.20435 14 3 14H17C17.7956 14 18.5587 13.6839 19.1213 13.1213C19.6839 12.5587 20 11.7956 20 11V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM9 10H5C4.73478 10 4.48043 9.89464 4.29289 9.70711C4.10536 9.51957 4 9.26522 4 9C4 8.73478 4.10536 8.48043 4.29289 8.29289C4.48043 8.10536 4.73478 8 5 8H9C9.26522 8 9.51957 8.10536 9.70711 8.29289C9.89464 8.48043 10 8.73478 10 9C10 9.26522 9.89464 9.51957 9.70711 9.70711C9.51957 9.89464 9.26522 10 9 10ZM15 10H13C12.7348 10 12.4804 9.89464 12.2929 9.70711C12.1054 9.51957 12 9.26522 12 9C12 8.73478 12.1054 8.48043 12.2929 8.29289C12.4804 8.10536 12.7348 8 13 8H15C15.2652 8 15.5196 8.10536 15.7071 8.29289C15.8946 8.48043 16 8.73478 16 9C16 9.26522 15.8946 9.51957 15.7071 9.70711C15.5196 9.89464 15.2652 10 15 10ZM18 4H2V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V4Z"
        fill="black"
      />
    </svg>
  )
}

const BicycleStorage = (props) => {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 15C7.20914 15 9 13.2091 9 11C9 8.79086 7.20914 7 5 7C2.79086 7 1 8.79086 1 11C1 13.2091 2.79086 15 5 15Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 15C19.2091 15 21 13.2091 21 11C21 8.79086 19.2091 7 17 7C14.7909 7 13 8.79086 13 11C13 13.2091 14.7909 15 17 15Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 11L7 4H14.5M5 1H8M17 11L14 1H18M18 1H18.5C18.8978 1 19.2794 1.15804 19.5607 1.43934C19.842 1.72064 20 2.10218 20 2.5C20 2.89782 19.842 3.27936 19.5607 3.56066C19.2794 3.84196 18.8978 4 18.5 4H18"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const ArrowUpperRight = (props) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.523 1.53941L1.50808 18.6178M18.523 1.53941L18.5229 18.618M18.523 1.53941L1.50804 1.53947"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const TextDivider = (props) => {
  return (
    <svg
      width="10"
      height="21"
      viewBox="0 0 10 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.82609 0H9.99998L2.17389 21H0L7.82609 0Z" fill="#65C5EF" />
    </svg>
  )
}

const FootballSvg = (props) => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.3736 3.63978C14.5398 2.79937 13.5476 2.13272 12.4544 1.67842C11.3612 1.22412 10.1887 0.991214 9.00482 0.993176C7.82072 0.991324 6.648 1.22428 5.5545 1.67856C4.46101 2.13284 3.46844 2.79944 2.63422 3.63978C-0.878074 7.15118 -0.878074 12.8669 2.63422 16.3792C3.46846 17.2198 4.46115 17.8865 5.55483 18.3408C6.64851 18.7951 7.82144 19.0279 9.00572 19.0258C10.1895 19.0279 11.3619 18.7951 12.4552 18.341C13.5484 17.8868 14.5406 17.2203 15.3745 16.3801C18.8868 12.8687 18.8868 7.15298 15.3736 3.63978ZM9.16697 2.80292H8.84357C8.89852 2.80202 8.95077 2.79482 9.00572 2.79482C9.06067 2.79482 9.11202 2.80202 9.16697 2.80292ZM14.6277 14.5136H12.6072L11.474 16.78C10.6834 17.0721 9.84758 17.2224 9.00482 17.2241C8.1605 17.2226 7.32309 17.072 6.53117 16.7791L5.40064 14.5226H3.38731C2.64452 13.5991 2.1422 12.506 1.92528 11.3409L3.599 9.10866L2.5036 6.91697C2.85313 6.17342 3.32821 5.49569 3.90798 4.91354C4.71943 4.09926 5.71403 3.49107 6.80862 3.13983L9.00392 4.60456L11.2001 3.14073C12.2944 3.49222 13.2889 4.10002 14.1008 4.91354C14.6799 5.495 15.1547 6.17178 15.5042 6.91426L14.4088 9.10866L16.0826 11.3409C15.8666 12.5022 15.3669 13.5921 14.6277 14.5136Z"
        fill="#1D1B1C"
      />
      <path
        d="M5.85156 9.10849L7.20279 12.7118H10.8061L12.1573 9.10849L9.00443 6.85645L5.85156 9.10849Z"
        fill="#1D1B1C"
      />
    </svg>
  )
}

const SwimmingSvg = (props) => {
  return (
    <svg
      width="21"
      height="11"
      viewBox="0 0 21 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.0078 3C14.5601 3 15.0078 2.55228 15.0078 2C15.0078 1.44772 14.5601 1 14.0078 1C13.4555 1 13.0078 1.44772 13.0078 2C13.0078 2.55228 13.4555 3 14.0078 3Z"
        stroke="#1D1B1C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.00781 4L8.00781 2L11.5078 5L10.0078 7M1.00781 9.75C1.3191 9.90478 1.66031 9.99008 2.00781 10C2.39731 10.0082 2.78294 9.92138 3.13139 9.74715C3.47984 9.57293 3.78064 9.31649 4.00781 9C4.23498 8.68351 4.53578 8.42707 4.88424 8.25285C5.23269 8.07862 5.61832 7.99184 6.00781 8C6.39731 7.99184 6.78294 8.07862 7.13139 8.25285C7.47984 8.42707 7.78064 8.68351 8.00781 9C8.23498 9.31649 8.53578 9.57293 8.88424 9.74715C9.23269 9.92138 9.61832 10.0082 10.0078 10C10.3973 10.0082 10.7829 9.92138 11.1314 9.74715C11.4798 9.57293 11.7806 9.31649 12.0078 9C12.235 8.68351 12.5358 8.42707 12.8842 8.25285C13.2327 8.07862 13.6183 7.99184 14.0078 8C14.3973 7.99184 14.7829 8.07862 15.1314 8.25285C15.4798 8.42707 15.7806 8.68351 16.0078 9C16.235 9.31649 16.5358 9.57293 16.8842 9.74715C17.2327 9.92138 17.6183 10.0082 18.0078 10C18.3553 9.99008 18.6965 9.90478 19.0078 9.75"
        stroke="#1D1B1C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const IceSkatingSvg = (props) => {
  return (
    <svg
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.00781 15H18.0078C18.273 15 18.5274 14.8946 18.7149 14.7071C18.9025 14.5196 19.0078 14.2652 19.0078 14M7.00781 11V15M13.0078 11V15M3.91281 1H7.33081C7.53063 1.00013 7.72584 1.06011 7.89124 1.17223C8.05665 1.28434 8.18468 1.44344 8.25881 1.629L9.40181 4.485C9.58771 4.95 9.88706 5.36106 10.2726 5.68071C10.6581 6.00035 11.1174 6.21841 11.6088 6.315L16.3258 7.241C16.7997 7.33416 17.2265 7.58903 17.5333 7.96204C17.8401 8.33505 18.0078 8.80303 18.0078 9.286V10C18.0078 10.2652 17.9025 10.5196 17.7149 10.7071C17.5274 10.8946 17.273 11 17.0078 11H3.11281C2.97306 11 2.83485 10.9707 2.70711 10.914C2.57937 10.8573 2.46494 10.7745 2.37118 10.6708C2.27743 10.5672 2.20645 10.445 2.16281 10.3123C2.11916 10.1795 2.10384 10.0391 2.11781 9.9L2.91781 1.9C2.9426 1.65334 3.05813 1.42468 3.24198 1.25838C3.42584 1.09208 3.66491 0.999997 3.91281 1Z"
        stroke="#1D1B1C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const VolleybalSvg = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.00781 15C11.8738 15 15.0078 11.866 15.0078 8C15.0078 4.13401 11.8738 1 8.00781 1C4.14182 1 1.00781 4.13401 1.00781 8C1.00781 11.866 4.14182 15 8.00781 15Z"
        stroke="#1D1B1C"
        strokeWidth="1.55556"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00781 8C8.61715 9.07932 9.53493 9.95206 10.6435 10.5064C11.7521 11.0607 13.001 11.2712 14.23 11.1111M4.50781 9.16667C5.14353 10.4634 6.07149 11.5949 7.21867 12.4721C8.36585 13.3494 9.70095 13.9485 11.1189 14.2222"
        stroke="#1D1B1C"
        strokeWidth="1.55556"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00728 8.00007C6.76793 7.98811 5.55324 8.34655 4.51893 9.02944C3.48462 9.71233 2.67782 10.6885 2.20194 11.833M8.74694 4.38574C7.30601 4.28781 5.86201 4.52562 4.52857 5.08045C3.19514 5.63529 2.00866 6.492 1.0625 7.58318"
        stroke="#1D1B1C"
        strokeWidth="1.55556"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00673 7.99991C8.63674 6.93256 8.93363 5.70139 8.85935 4.46421C8.78508 3.22703 8.34302 2.04021 7.58984 1.05591M10.7671 10.4476C11.5723 9.24874 12.0883 7.87942 12.2745 6.44733C12.4608 5.01525 12.3122 3.55948 11.8404 2.19457"
        stroke="#1D1B1C"
        strokeWidth="1.55556"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const SearchSvg = (props) => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.71 17.2881L14.31 13.8981C15.407 12.5005 16.0022 10.7747 16 8.99805C16 7.4158 15.5308 5.86908 14.6518 4.55349C13.7727 3.2379 12.5233 2.21252 11.0615 1.60701C9.59966 1.00151 7.99113 0.843086 6.43928 1.15177C4.88743 1.46045 3.46197 2.22238 2.34315 3.3412C1.22433 4.46002 0.462403 5.88548 0.153721 7.43733C-0.15496 8.98918 0.00346625 10.5977 0.608967 12.0595C1.21447 13.5213 2.23985 14.7708 3.55544 15.6498C4.87103 16.5289 6.41775 16.9981 8 16.9981C9.77666 17.0003 11.5025 16.405 12.9 15.3081L16.29 18.708C16.383 18.8018 16.4936 18.8762 16.6154 18.9269C16.7373 18.9777 16.868 19.0038 17 19.0038C17.132 19.0038 17.2627 18.9777 17.3846 18.9269C17.5064 18.8762 17.617 18.8018 17.71 18.708C17.8037 18.6151 17.8781 18.5045 17.9289 18.3826C17.9797 18.2608 18.0058 18.1301 18.0058 17.9981C18.0058 17.866 17.9797 17.7353 17.9289 17.6135C17.8781 17.4916 17.8037 17.381 17.71 17.2881ZM2 8.99805C2 7.81136 2.3519 6.65132 3.01119 5.66463C3.67047 4.67793 4.60755 3.9089 5.7039 3.45477C6.80026 3.00065 8.00666 2.88183 9.17055 3.11334C10.3344 3.34485 11.4035 3.91629 12.2426 4.75541C13.0818 5.59452 13.6532 6.66362 13.8847 7.82751C14.1162 8.99139 13.9974 10.1978 13.5433 11.2942C13.0892 12.3905 12.3201 13.3276 11.3334 13.9869C10.3467 14.6462 9.18669 14.9981 8 14.9981C6.4087 14.9981 4.88258 14.3659 3.75736 13.2407C2.63214 12.1155 2 10.5893 2 8.99805Z"
        fill="#272727"
      />
    </svg>
  )
}

const Search2 = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.69231 5.92308C9.69231 4.88542 9.32352 3.9978 8.58594 3.26022C7.84836 2.52264 6.96074 2.15385 5.92308 2.15385C4.88542 2.15385 3.9978 2.52264 3.26022 3.26022C2.52264 3.9978 2.15385 4.88542 2.15385 5.92308C2.15385 6.96074 2.52264 7.84836 3.26022 8.58594C3.9978 9.32352 4.88542 9.69231 5.92308 9.69231C6.96074 9.69231 7.84836 9.32352 8.58594 8.58594C9.32352 7.84836 9.69231 6.96074 9.69231 5.92308ZM14 12.9231C14 13.2147 13.8934 13.4671 13.6803 13.6803C13.4671 13.8934 13.2147 14 12.9231 14C12.6202 14 12.3678 13.8934 12.1659 13.6803L9.28005 10.8029C8.27604 11.4984 7.15705 11.8462 5.92308 11.8462C5.12099 11.8462 4.35397 11.6905 3.622 11.3792C2.89002 11.0679 2.25901 10.6472 1.72897 10.1172C1.19892 9.58714 0.778245 8.95613 0.466947 8.22416C0.155649 7.49219 0 6.72516 0 5.92308C0 5.12099 0.155649 4.35397 0.466947 3.622C0.778245 2.89002 1.19892 2.25901 1.72897 1.72897C2.25901 1.19892 2.89002 0.778245 3.622 0.466947C4.35397 0.155649 5.12099 0 5.92308 0C6.72516 0 7.49219 0.155649 8.22416 0.466947C8.95613 0.778245 9.58714 1.19892 10.1172 1.72897C10.6472 2.25901 11.0679 2.89002 11.3792 3.622C11.6905 4.35397 11.8462 5.12099 11.8462 5.92308C11.8462 7.15705 11.4984 8.27604 10.8029 9.28005L13.6887 12.1659C13.8962 12.3734 14 12.6258 14 12.9231Z"
        fill="#1D1D1B"
      />
    </svg>
  )
}

const GreenDot = (props) => {
  return (
    <svg
      width="4"
      height="4"
      viewBox="0 0 4 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="2" cy="2" r="2" fill="#2E842A" />
    </svg>
  )
}

const Filter = (props) => {
  return <FilterAltOutlinedIcon {...props} />
}

const Calendar = (props) => {
  return <CalendarTodayOutlinedIcon {...props} />
}

const Facebook = (props) => {
  return (
    <svg
      width="9"
      height="20"
      viewBox="0 0 9 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.29749 20V10.6154H0V7.23652H2.29749V4.3505C2.29749 2.08264 3.65463 0 6.78176 0C8.04789 0 8.98413 0.1311 8.98413 0.1311L8.91036 3.28642C8.91036 3.28642 7.95554 3.27638 6.9136 3.27638C5.78591 3.27638 5.60524 3.83768 5.60524 4.7693V7.23652H9L8.85229 10.6154H5.60524V20H2.29749Z"
        fill="#1F486A"
      />
    </svg>
  )
}

const XTwitter = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M9.522 6.924L15.479 0H14.067L8.895 6.012L4.764 0H0L6.247 9.091L0 16.352H1.412L6.873 10.003L11.234 16.352H16L9.522 6.924H9.522ZM7.589 9.171L6.956 8.266L1.92 1.0626H4.088L8.152 6.876L8.785 7.781L14.068 15.337H11.8997L7.589 9.171V9.171Z"
        fill="#1F486A"
      />
    </svg>
  )
}

const Email = (props) => {
  return (
    <svg
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.043 3.373V10.5C16.043 11.163 15.7796 11.7989 15.3107 12.2678C14.8419 12.7366 14.206 13 13.543 13H2.54297C1.87993 13 1.24404 12.7366 0.775202 12.2678C0.306361 11.7989 0.0429688 11.163 0.0429688 10.5V3.373L7.78897 7.931C7.86592 7.97638 7.95363 8.00032 8.04297 8.00032C8.13231 8.00032 8.22002 7.97638 8.29697 7.931L16.043 3.373ZM13.543 3.09527e-08C14.1581 -9.6716e-05 14.7517 0.226607 15.2102 0.63674C15.6686 1.04687 15.9598 1.61164 16.028 2.223L8.04297 6.92L0.0579689 2.223C0.126127 1.61164 0.417293 1.04687 0.875764 0.63674C1.33423 0.226607 1.92782 -9.6716e-05 2.54297 3.09527e-08H13.543Z"
        fill="#1F486A"
      />
    </svg>
  )
}

const WhatsApp = (props) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6253 11.985C14.3758 11.8608 13.1524 11.2625 12.9247 11.1792C12.6969 11.0967 12.5311 11.0558 12.3645 11.3042C12.1987 11.5508 11.7222 12.1092 11.5774 12.2742C11.4317 12.44 11.2868 12.46 11.0381 12.3367C10.7894 12.2117 9.98726 11.9508 9.03688 11.1075C8.2975 10.4509 7.79761 9.64002 7.65275 9.39169C7.50789 9.14419 7.63684 9.01002 7.7616 8.88669C7.87381 8.77585 8.01029 8.59752 8.13506 8.45335C8.25982 8.30835 8.30085 8.20502 8.38375 8.03919C8.46748 7.87419 8.42562 7.73002 8.36281 7.60585C8.30085 7.48169 7.80347 6.26252 7.59581 5.76669C7.39401 5.28419 7.18886 5.35003 7.03646 5.34169C6.89076 5.33503 6.72497 5.33336 6.55918 5.33336C6.39338 5.33336 6.12376 5.39503 5.896 5.64336C5.66741 5.89086 5.02516 6.49002 5.02516 7.70919C5.02516 8.92752 5.9161 10.105 6.04086 10.2709C6.16563 10.4359 7.7951 12.9375 10.2912 14.01C10.8857 14.265 11.3488 14.4175 11.7097 14.5308C12.3059 14.72 12.8485 14.6933 13.2772 14.6292C13.7545 14.5583 14.7492 14.03 14.9569 13.4517C15.1637 12.8733 15.1637 12.3775 15.1018 12.2742C15.0398 12.1708 14.874 12.1092 14.6245 11.985H14.6253ZM10.0852 18.1542H10.0819C8.59931 18.1544 7.14395 17.7578 5.86837 17.0058L5.56692 16.8275L2.43358 17.6458L3.27009 14.6058L3.07332 14.2942C2.24446 12.9811 1.80582 11.4615 1.80809 9.91085C1.80976 5.36919 5.52254 1.6742 10.0886 1.6742C12.2992 1.6742 14.3775 2.53253 15.9399 4.08919C16.7106 4.85301 17.3214 5.76133 17.7371 6.7616C18.1528 7.76188 18.365 8.83425 18.3615 9.91668C18.3599 14.4583 14.6471 18.1542 10.0852 18.1542ZM17.129 2.9067C16.2064 1.98247 15.1086 1.24965 13.8994 0.7507C12.6902 0.251754 11.3934 -0.00339687 10.0844 3.41479e-05C4.59644 3.41479e-05 0.128378 4.44586 0.126703 9.91002C0.12416 11.649 0.582532 13.3578 1.45557 14.8642L0.0429688 20L5.32158 18.6217C6.7818 19.4134 8.41816 19.8283 10.081 19.8283H10.0852C15.5732 19.8283 20.0412 15.3825 20.0429 9.91752C20.047 8.61525 19.7915 7.32511 19.2913 6.12172C18.7912 4.91833 18.0562 3.82559 17.129 2.9067Z"
        fill="#1F486A"
      />
    </svg>
  )
}

const LinkedIn = (props) => {
  return (
    <svg
      enableBackground="new 0 0 512 512"
      id="Layer_1"
      version="1.1"
      viewBox="0 0 512 512"
      // xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width={21}
      height={20}
      {...props}
    >
      <g>
        <path
          fill="#1F486A"
          d="M173.5,508.7c0-2.4,0-4.7,0-7c0-107.7,0-215.3,0-323c0-3-0.2-5.4,4.2-5.4c31.8,0.2,63.7,0.1,95.5,0.1   c0.8,0,1.6,0.2,2.9,0.4c0,14,0,27.8,0,41.7c0.4,0.1,0.7,0.3,1.1,0.4c4-5.2,7.8-10.6,12-15.7c12.2-15,27.5-26,45.5-32.6   c9.8-3.6,20.5-5.8,30.8-6.3c14.2-0.6,28.7-0.4,42.7,2c23,3.9,43.3,14.1,60.1,30.8c15.9,15.7,26.4,34.6,32.1,55.9   c3,11.3,4.9,22.9,6.7,34.4c1.2,8,1.8,16.2,1.8,24.2c0.1,65,0.1,130,0.1,195c0,1.6-0.1,3.2-0.2,5.3c-1.9,0-3.5,0-5.1,0   c-30.7,0-61.3-0.1-92,0.1c-5.1,0-6-1.7-6-6.3c0.1-59.8,0.3-119.7-0.1-179.5c-0.1-10.5-1.1-21.4-3.8-31.5   c-4.9-17.7-16.1-30.5-33.9-37c-15-5.5-30.2-6.5-45.6-2.6c-17,4.3-28.7,15.6-37.5,30.5c-6.8,11.5-8.1,24.2-8.2,36.9   c-0.4,60.7-0.2,121.3-0.2,182c0,2.3,0,4.6,0,7.1C241.9,508.7,208,508.7,173.5,508.7z"
        />
        <path
          fill="#1F486A"
          d="M109.1,342.6c0,53.3-0.1,106.6,0.1,159.9c0,4.8-1,6.6-6.3,6.5c-30.5-0.3-61-0.3-91.5,0c-5,0-6.1-1.7-6.1-6.3   c0.1-107.1,0.1-214.3,0-321.4c0-4.3,1.2-5.8,5.6-5.8c30.8,0.2,61.6,0.2,92.5,0c4.8,0,5.7,1.8,5.7,6.1   C109.1,235.3,109.1,289,109.1,342.6z"
        />
        <path
          fill="#1F486A"
          d="M56.1,4.8C89,4,108.7,27.9,108.8,57.2c0.1,30-20.9,52.4-53.9,52.3c-30.7,0-52.4-22-52.3-53.1C2.7,27.3,23.8,3.8,56.1,4.8z"
        />
      </g>
    </svg>
  )
}

const svgDict = {
  arrowRight: ArrowRight,
  ov: Ov,
  cafetaria: Cafetaria,
  bike: BicycleStorage,
  pin: Pin,
  shirt: Shirt,
  shower: Shower,
  parking: Parking,
  arrowUpperRight: ArrowUpperRight,
  textdivider: TextDivider,
  football: FootballSvg,
  swimming: SwimmingSvg,
  iceSkating: IceSkatingSvg,
  volleyball: VolleybalSvg,
  search: SearchSvg,
  search2: Search2,
  filter: Filter,
  calendar: Calendar,
  greenDot: GreenDot,
  facebook: Facebook,
  twitter: XTwitter,
  email: Email,
  whatsapp: WhatsApp,
  close: CloseIcon,
  linkedin: LinkedIn,
}

const SvgElement = ({ variant, ...restProps }) => {
  if (variant in svgDict) {
    const Svg = svgDict[variant]
    return <Svg {...restProps} />
  }

  return <ImportSvgElement variant={variant} {...restProps} />
}

export default SvgElement

export const ImportSvgElement = ({
  variant,
  width = 21,
  height = 16,
  ...restProps
}) => {
  if (!variant) {
    return null
  }
  let file
  try {
    file = require(`./svgs/${variant}`)
  } catch (err) {
    file = null
  }
  if (!file?.default?.src) {
    return null
  }
  return (
    <SVG
      src={file?.default?.src}
      width={width}
      height={height}
      title=""
      {...restProps}
    />
  )
}
