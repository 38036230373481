import {
  grey,
  primary_green,
  secondary_green,
  tertiary_green,
  text_blue,
  white,
} from '@/core/colors'

export const primaryLinkStyling = `
    display: inline-flex;
    padding: 15px 40px;
    color: ${white};
    border-radius: 65px;
    background-color: ${secondary_green};
    &:hover {
        background-color: ${tertiary_green};
        textDecoration: none !important;
    };
    &:focus {
        background-color: ${tertiary_green};
        text-decoration: none !important;
        outlineColor: #000 !important;
    };
    &:active {
        background-color: ${tertiary_green};
    };
    svg {
        margin-left: 24px;
    };
    @media screen and (max-width: 340px) {
        max-width: 100%;
    };
`

export const whiteLinkStyling = `
    display: inline-flex;
    padding: 15px 40px;
    color: ${white};
    border-radius: 45px;
    background-color: ${white};
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    };
    &:focus {
        text-decoration: underline;
    };
    svg {
        margin-left: 24px;
        path {
            fill: ${text_blue};
        };
    };
    .MuiTypography-ButtonText {
        color: ${text_blue};
    };
`

export const arrowLinkStyling = `
    margin-left: 20px;
    display: inline-block;
    padding: 15px 40px;
    color: ${white};
    background-color: ${primary_green};
    height: 76px;
    width: 90px;
    &:hover {
        background-color: ${tertiary_green};
    };
    &:focus {
        background-color: ${tertiary_green};
        outline-color: #000 !important
    };
    &:active {
        background-color: ${tertiary_green};
    };

    .svgContainer {
        padding-top: 10px;
        margin-left: -10px;
    };
`

export const borderLinkStyling = `
    display: block;
    padding: 42px 58px;
    background-color: transparent;
    border: 1px solid ${grey};
    position: relative;
    svg {
        position: absolute;
        right: 58px;
        top: 0;
        float: right;
        height: 100%;
        width: 24px;
        path {
            stroke: null;
            fill: black;
        };
    };
`

export const baseLinkStyling = `
    text-decoration: none;
    color: inherit;
    
    &.Mui-focusVisible {
        > span {
            outline: #007aff auto 1px;
            img {
                background-color: #007aff !important;
            }
        };
        &.focusibleOutline {
            outline: #007aff auto 1px;
        }
    };
`

export const baseArrowLinkStyling = `
    text-decoration: none;
    display: flex;
    flex-direction: row;
    gap: 43px;
    align-items: center;
`

export const innerSkewedLinkStyling = `
    text-decoration: none;
    color: inherit;
    &.Mui-focusVisible {
        outline: 0;
        > div > .skewWrapper {
            &:before {
                outline: ${secondary_green} auto 1px;
            }
        },
        .skewContainerContainer > div > .skewWrapper {
            &:before  {
                outline: ${secondary_green} auto 1px;
            }
        }
    };
`

export const returnLinkStyling = `
    display: inline-flex;
    padding: 15px 40px;
    color: ${white};
    border-radius: 65px;
    background-color: ${secondary_green};
    &:hover {
        background-color: ${tertiary_green};
        text-decoration: none !important;
    };
    &:focus {
        background-color: ${tertiary_green};
        text-decoration: none !important;
        outline-color: #000 !important;
    };
    &:active {
        background-color: ${tertiary_green};
    };
    svg {
        margin-right: 12px;
        width: 10px;
    };
`

export const linkStylingDict = {
  primary: primaryLinkStyling,
  white: whiteLinkStyling,
  arrow: arrowLinkStyling,
  border: borderLinkStyling,
  return: returnLinkStyling,
  base: baseLinkStyling,
  baseArrow: baseArrowLinkStyling,
  innerSkewed: innerSkewedLinkStyling,
}
