import React from 'react'
import styled from 'styled-components'

// Main container
const MainWidthWrapper = styled.div`
  margin: 0 auto;
  background: ${(props) => props.background};

  > div {
    margin: 0 auto;
    ${(props) => (props.relative ? 'position: relative;' : '')}
  }

  .full {
    max-width: 1700px;
    @media screen and (max-width: 1740px) {
      padding: 0px 20px;
      box-sizing: border-box;
    }
  }

  .semi {
    max-width: 1550px;
    @media screen and (max-width: 1740px) {
      padding: 0px 80px;
      box-sizing: border-box;
    }
  }

  .small {
    max-width: 1350px;
    @media screen and (max-width: 1740px) {
      padding: 0px 20px;
      box-sizing: border-box;
    }
  }

  @media screen and (max-width: 1050px) {
    > div {
      padding: 0 20px !important;
    }
  }

  /* @media screen and (max-width: 340px) {
        > div {
            padding: 0 12px !important;
        }
    } */
`

// Width Variants
const WidthContainer = ({
  variant = 'full',
  children,
  relative,
  background = null,
}) => {
  return (
    <MainWidthWrapper relative={relative} background={background}>
      <div className={variant}>{children}</div>
    </MainWidthWrapper>
  )
}
export default WidthContainer
