export const shouldOpenNewTab = (href) => {
  // Check based on the href if the link should open in a new tab
  const isExternal = href?.includes('http://') || href?.includes('https://')
  if (typeof window === 'undefined') {
    return isExternal
  }
  let origin = window?.location?.origin
  let checkHref = href || ''
  if (checkHref.includes('localhost:8000')) {
    checkHref.replace('localhost:8000', 'localhost:3000')
  }
  return isExternal && !(origin && checkHref.includes(origin))
}
