import React from 'react'
import styled from 'styled-components'

import ColorBar from '../../elements/colorbars'
import WidthContainer from '../../elements/widthContainer'
import Logo from '../../elements/logo'
import search from './assets/search.png'
import BigMenu from './bigmenu'
import { ImageBuilderFrontend } from '../../elements/imageBuilder'
import ButtonElement from '../../elements/buttons'
import Link from 'next/link'

import Skeleton from '@mui/material/Skeleton'

const MenuWrapper = styled.div`
    height: 119px;
    padding-top: 30px;

    @media screen and (max-width: 640px) {
        padding-top: 10px;
        height: 80px;
    }

    div.menuwrap {
        width: 33%;
        display: inline-block;
        vertical-align: top;

        &:nth-child(1) {
            width: 230px;

            img {
                width: 210px !important;
                height: auto;
            }

            a {
                padding: 0;
            }

            @media screen and (max-width: 640px) {
                width: 160px;

                img {
                    width: 140px;
                    height: auto;
                }

                a {
                    position: relative;
                    top: 8px;
                }
            }
            @media screen and (max-width: 340px) {
                width: 50%;

                img {
                    width: auto;
                    height: auto;
                }
            }
        }

        &:nth-child(2) {
            width: calc(100% - 520px);

            @media screen and (max-width: 1064px) {
                display: none;
            }
        }

        &:nth-child(3) {
            width: 290px;

            @media screen and (max-width: 1064px) {
                float: right;
                width: 250px;
                .rightmenu {
                    text-align: right;
                }
                span {
                    display: none;
                }
                span.linkWrapper {
                    display: inline-block;
                }
            }
            @media screen and (max-width: 450px) {
                width: 50%;
                position: absolute;
                top: 10px;
                right: 20px;
                span {
                    display: none;
                }
                span.linkWrapper {
                    display: inline-block;
                }
            }
            }
        }
    }

    nav {
        text-align: center;
        padding-top: 18px;
    }

    a,
    span {
        font-family: 'Dosis';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        padding: 0px 14px;
        color: #1d1d1b;

        &.active {
            font-weight: 700;
        }
    }

    .rightmenu {
        padding-top: 18px;
        float: right;
        text-align: right;
        min-width: 320px;

        > button {
            padding-top: 0;
        }

        @media screen and (max-width: 450px) {
            min-width: 0;
        }

        > div {
            display: inline-block;
            vertical-align: top;
            padding: 0 0 0 10px;

            &.search {
                padding-right: 16px;
                border-right: 1px solid rgba(0, 0, 0, 0.2);
                .searchTextContainer {
                    display: inline-block;
                    vertical-align: middle;
                    outline: 0;

                    @media screen and (max-width: 500px) {
                        display: none;
                    }
                }
                a {
                    padding: 0;
                }
                .imageContainer {
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    vertical-align: middle;
                    > span {
                        width: 100% !important;
                        height: 100% !important;
                        position: relative !important;
                    }
                }
            }
        }

        img {
            position: relative;
            top: -3px;
        }

        span {
            vertical-align: top;
            display: inline-block;
        }

        .searchIcon {
            display: inline-block;
            width: 39px;
            padding-top: 5px;

            div {
                height: 3px;
                margin-bottom: 5px;
                background: #2e842a;
                width: 29px;
                float: right;

                &:last-child {
                    width: 20px;
                }
            }
        }
    }

    .menu {
        cursor: pointer;
    }
`

const MenuModule = ({ bigmenu, contact, noLinks = false, loadingVariant }) => {
  const [open, setIsOpen] = React.useState(false)
  return (
    <div>
      <MenuWrapper>
        <WidthContainer variant="full">
          <div className="menuwrap">
            {!loadingVariant && (
              <Link href={noLinks ? 'https://sport050.nl' : '/'}>
                <Logo />
              </Link>
            )}

            {loadingVariant && (
              <Skeleton variant="rectangular" width={250} height={60} />
            )}
          </div>
          <div className="menuwrap">
            {!loadingVariant && !noLinks && (
              <nav>
                <ButtonElement variant="base" href="/sporten-in-groningen">
                  {' '}
                  Ik wil sporten{' '}
                </ButtonElement>
                |
                <ButtonElement variant="base" href="/informatie">
                  {' '}
                  Informatie{' '}
                </ButtonElement>{' '}
                |
                <ButtonElement variant="base" href="/sportlocaties">
                  {' '}
                  Sportlocaties{' '}
                </ButtonElement>
              </nav>
            )}

            {loadingVariant && (
              <nav>
                <a style={{ display: 'inline-block' }}>
                  <Skeleton variant="rectangular" width={160} height={30} />
                </a>
                <a style={{ display: 'inline-block' }}>
                  <Skeleton variant="rectangular" width={160} height={30} />
                </a>
                <a style={{ display: 'inline-block' }}>
                  <Skeleton variant="rectangular" width={160} height={30} />
                </a>
              </nav>
            )}
          </div>
          <div className="menuwrap">
            <div className="rightmenu">
              {!noLinks && (
                <>
                  <div className="search">
                    <ButtonElement variant="base" href="/zoeken">
                      <span className="searchTextContainer"> Zoeken </span>
                      <div className="imageContainer">
                        <ImageBuilderFrontend
                          image={search}
                          alt="Zoeken"
                          width={16}
                          height={16}
                        />
                      </div>
                    </ButtonElement>
                  </div>
                  <ButtonElement
                    variant="empty"
                    className="menu"
                    handleClick={() => setIsOpen(true)}
                  >
                    <span> Menu </span>
                    <div className="searchIcon">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </ButtonElement>
                </>
              )}
            </div>
          </div>
        </WidthContainer>
        <BigMenu
          isOpen={open}
          setIsOpen={setIsOpen}
          data={bigmenu}
          contact={contact}
        />
      </MenuWrapper>
      <ColorBar />
      {loadingVariant && (
        <Skeleton variant="rectangular" width="100%" height={400} />
      )}
    </div>
  )
}
export default MenuModule
