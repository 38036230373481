import React from 'react'
import styled from 'styled-components'

// Logos
import greenLogo from './assets/logo.svg'
import whiteLogo from './assets/white.png'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { primary_green } from '../../colors'
import { ImageBuilderFrontend } from '../imageBuilder'
import ButtonElement from '../buttons'

const imgTable = {
  green: greenLogo,
  white: whiteLogo,
}

// Styled
const LogoWrapper = styled.div`
  display: inline-block;
  > a > span {
    display: inline-block;
    vertical-align: middle;
  }
  img {
    display: inline-block;
    vertical-align: middle;
  }
  svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 19px;
    path {
      color: ${primary_green};
    }
  }
`

const Logo = ({
  variant = 'green',
  href = '/',
  alt = '',
  isSubsite = false,
  _overwriteAltText
}) => {
  const src = imgTable[variant]
  return (
    <LogoWrapper className="js-logo">
      {isSubsite && href ? (
        <ButtonElement variant="base" href={href}>
          <ChevronLeftIcon width={16} color={primary_green} />
          <span style={{ color: '#000', fontWeight: 'bold !important', textTransform: 'uppercase', fontSize: '0.8rem !important' }}>
            Ga naar Sport050.nl
          </span>
        </ButtonElement>
      ) : (
        <ImageBuilderFrontend
          image={src}
          alt={_overwriteAltText || alt || 'Sport050'}
          width={632}
          height={199}
        />
      )}
    </LogoWrapper>
  )
}

export default Logo
