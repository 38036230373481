import React from 'react'
import styled from 'styled-components'

// Image imports
import long from './assets/long.png'
import short1 from './assets/short1.png'
import short2 from './assets/short2.png'
import short3 from './assets/short3.png'
import extrashort from './assets/extrashort.png'

// Variant table
const imgTable = {
  long: long,
  small1: short1,
  small2: short2,
  small3: short3,
  extrasmall: extrashort,
}

// Styled wrapper
const Wrapper = styled.div`
  height: 20px;
  max-height: 20px;
  width: 100%;
  display: block;
  background-image: url(${(props) => props.variant});
  background-position: center center;
  position: relative;
  ${(props) => props.customStyling}
`

// Component
const BarElement = ({ variant = 'long', children, customStyling }) => {
  const asset = imgTable[variant]
  const bar =
    typeof asset == 'string'
      ? asset
      : asset && asset.src
      ? asset.src
      : imgTable['long']

  return (
    <Wrapper variant={bar} customStyling={customStyling}>
      {' '}
      {children}{' '}
    </Wrapper>
  )
}
export default BarElement
