import React from 'react'
import styled from 'styled-components'
import { Typography } from '@mui/material'
import { secondary_green } from '../colors'

export const Title = ({ children, component = 'h2', ...restProps }) => {
  return (
    <Typography component={component} variant="Title" {...restProps}>
      {children}
    </Typography>
  )
}

export const Subtitle = ({ children, component = 'h2', ...restProps }) => {
  return (
    <Typography component={component} variant="Subtitle" {...restProps}>
      {children}
    </Typography>
  )
}

export const ButtonText = ({ children, component = 'div', ...restProps }) => {
  return (
    <Typography component={component} variant="ButtonText" {...restProps}>
      {children}
    </Typography>
  )
}
export const SecondaryButtonText = ({
  children,
  component = 'div',
  ...restProps
}) => {
  return (
    <Typography
      component={component}
      variant="SecondaryButtonText"
      {...restProps}
    >
      {children}
    </Typography>
  )
}

export const BlankButtonText = ({
  children,
  component = 'div',
  ...restProps
}) => {
  return (
    <Typography component={component} variant="BlankButtonText" {...restProps}>
      {children}
    </Typography>
  )
}

export const MoreButtonText = ({
  children,
  component = 'div',
  ...restProps
}) => {
  return (
    <Typography component={component} variant="MoreButtonText" {...restProps}>
      {children}
    </Typography>
  )
}

export const IconButtonText = ({
  children,
  component = 'div',
  ...restProps
}) => {
  return (
    <Typography component={component} variant="IconButtonText" {...restProps}>
      {children}
    </Typography>
  )
}

export const PlusButtonText = ({
  children,
  component = 'div',
  ...restProps
}) => {
  return (
    <Typography component={component} variant="PlusButtonText" {...restProps}>
      {children}
    </Typography>
  )
}

export const HeaderTitle = ({ children, component = 'h1', ...restProps }) => {
  return (
    <Typography component={component} variant="HeaderTitle" {...restProps}>
      {children}
    </Typography>
  )
}

export const HeaderTitleWhite = ({
  children,
  component = 'h1',
  ...restProps
}) => {
  return (
    <Typography component={component} variant="HeaderTitleWhite" {...restProps}>
      {children}
    </Typography>
  )
}

export const HeaderSubTitleWhite = ({
  children,
  component = 'div',
  ...restProps
}) => {
  return (
    <Typography
      component={component}
      variant="HeaderSubTitleWhite"
      {...restProps}
    >
      {children}
    </Typography>
  )
}

export const HeaderTitle2Rows = ({
  children,
  component = 'h1',
  ...restProps
}) => {
  return (
    <Typography component={component} variant="Title" {...restProps}>
      {children}
    </Typography>
  )
}

export const Breadcrumb = ({ children, component = 'div', ...restProps }) => {
  return (
    <Typography component={component} variant="Breadcrumb" {...restProps}>
      {children}
    </Typography>
  )
}

export const P = ({ children, component = 'div', ...restProps }) => {
  return (
    <Typography component={component} variant="Paragraph" {...restProps}>
      {children}
    </Typography>
  )
}

export const CardText = ({ children, component = 'div', ...restProps }) => {
  return (
    <Typography component={component} variant="TextCards" {...restProps}>
      {children}
    </Typography>
  )
}

export const GreenTitle = ({ children, component = 'h3', ...restProps }) => {
  return (
    <Typography component={component} variant="GreenTitle" {...restProps}>
      {children}
    </Typography>
  )
}

export const BlockTitle = ({ children, component = 'h3', ...restProps }) => {
  return (
    <Typography component={component} variant="BlockTitle" {...restProps}>
      {children}
    </Typography>
  )
}

export const NewsCardTitle = ({ children, component = 'h3', ...restProps }) => {
  return (
    <Typography component={component} variant="NewsCardTitle" {...restProps}>
      {children}
    </Typography>
  )
}

export const NewsCardTitleElipsis = ({
  children,
  component = 'h3',
  ...restProps
}) => {
  return (
    <Typography
      component={component}
      variant="NewsCardTitleElipsis"
      {...restProps}
    >
      {children}
    </Typography>
  )
}

export const NewsCardCategory = ({
  children,
  component = 'div',
  ...restProps
}) => {
  return (
    <Typography component={component} variant="NewsCardCategory" {...restProps}>
      {children}
    </Typography>
  )
}

const NewsCardDateDot = styled.div`
  width: 4px;
  height: 4px;
  top: 10px;
  border-radius: 2px;
  background-color: ${secondary_green};
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
`

const NewsCardDateWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
`

export const NewsCardDateWithDot = ({
  children,
  component = 'div',
  showDot = true,
  ...restProps
}) => {
  return (
    <NewsCardDateWrapper {...restProps}>
      {showDot && <NewsCardDateDot></NewsCardDateDot>}
      <Typography component={component} variant="NewsCardDateWithDot">
        {children}
      </Typography>
    </NewsCardDateWrapper>
  )
}

export const NewsCardDate = ({
  children,
  component = 'div',
  color,
  ...restProps
}) => {
  return (
    <NewsCardDateWrapper {...restProps}>
      <Typography component={component} variant="NewsCardDate" color={color}>
        {children}
      </Typography>
    </NewsCardDateWrapper>
  )
}

export const EventBlockTitle = ({
  children,
  component = 'h2',
  ...restProps
}) => {
  return (
    <Typography component={component} variant="EventBlockTitle" {...restProps}>
      {children}
    </Typography>
  )
}

export const EventBlockSubtitle = ({
  children,
  component = 'div',
  ...restProps
}) => {
  return (
    <Typography
      component={component}
      variant="EventBlockSubtitle"
      {...restProps}
    >
      {children}
    </Typography>
  )
}

const DateWrapper = styled.span`
  display: block;
`

export const EventDateNumber = ({
  children,
  component = 'div',
  variant = 'BlockDateNumber',
}) => {
  return (
    <Typography component={component} variant={variant}>
      {children}
    </Typography>
  )
}

export const EventDateMonth = ({
  children,
  component = 'div',
  variant = 'BlockDateMonth',
}) => {
  return (
    <Typography component={component} variant={variant}>
      {children}
    </Typography>
  )
}

export const EventDate = ({ day, month, ...restProps }) => {
  return (
    <DateWrapper {...restProps}>
      <Typography component="p" variant="BlockDateNumber">
        {day}
      </Typography>{' '}
      <Typography component="p" variant="BlockDateMonth">
        {month}
      </Typography>
    </DateWrapper>
  )
}

export const CTAText = ({ children, component = 'div', ...restProps }) => {
  return (
    <Typography component={component} variant="CTAText">
      {children}
    </Typography>
  )
}

export const CTAInlineText = ({
  children,
  component = 'div',
  ...restProps
}) => {
  return (
    <Typography component={component} variant="CTAInlineText">
      {children}
    </Typography>
  )
}

export const SearchCardTitle = ({
  children,
  component = 'h3',
  ...restProps
}) => {
  return (
    <Typography
      component={component}
      variant="SearchResultCardTitle"
      {...restProps}
    >
      {children}
    </Typography>
  )
}

export const TagText = ({ children, component = 'div', ...restProps }) => {
  return (
    <Typography component={component} variant="TagText" {...restProps}>
      {children}
    </Typography>
  )
}

export const FacilityText = ({ children, component = 'div', ...restProps }) => {
  return (
    <Typography component={component} variant="FacilityText" {...restProps}>
      {children}
    </Typography>
  )
}

export const FAQTitle = ({ children, component = 'h3', ...restProps }) => {
  return (
    <Typography component={component} variant="FAQTitle" {...restProps}>
      {children}
    </Typography>
  )
}

export const FAQText = ({ children, component = 'div', ...restProps }) => {
  return (
    <Typography component={component} variant="FAQText" {...restProps}>
      {children}
    </Typography>
  )
}

export const HeaderColumnTitle = ({
  children,
  component = 'h2',
  ...restProps
}) => {
  return (
    <Typography
      component={component}
      variant="HeaderColumnTitle"
      {...restProps}
    >
      {children}
    </Typography>
  )
}

export const FooterColumnTitle = ({
  children,
  component = 'h3',
  ...restProps
}) => {
  return (
    <Typography
      component={component}
      variant="FooterColumnTitle"
      {...restProps}
    >
      {children}
    </Typography>
  )
}

export const FooterColumnItem = ({
  children,
  component = 'div',
  ...restProps
}) => {
  return (
    <Typography component={component} variant="FooterColumnItem" {...restProps}>
      {children}
    </Typography>
  )
}

export const FooterColumnItemThick = ({
  children,
  component = 'div',
  ...restProps
}) => {
  return (
    <Typography
      component={component}
      variant="FooterColumnItemThick"
      {...restProps}
    >
      {children}
    </Typography>
  )
}

export const FooterInfo = ({ children, component = 'div', ...restProps }) => {
  return (
    <Typography component={component} variant="FooterInfo" {...restProps}>
      {children}
    </Typography>
  )
}

export const FooterText = ({ children, component = 'div', ...restProps }) => {
  return (
    <Typography component={component} variant="FooterText" {...restProps}>
      {children}
    </Typography>
  )
}

export const FooterCopyright = ({
  children,
  component = 'div',
  ...restProps
}) => {
  return (
    <Typography component={component} variant="FooterCopyright" {...restProps}>
      {children}
    </Typography>
  )
}

export const ListTitle = ({ children, component = 'h3', ...restProps }) => {
  return (
    <Typography component={component} variant="ListTitle" {...restProps}>
      {children}
    </Typography>
  )
}

export const ReadMoreWhite = ({
  children,
  component = 'div',
  ...restProps
}) => {
  return (
    <Typography component={component} variant="ReadMoreWhite" {...restProps}>
      {children}
    </Typography>
  )
}

export const MoreTitle = ({ children, component = 'h4', ...restProps }) => {
  return (
    <Typography component={component} variant="MoreTitle" {...restProps}>
      {children}
    </Typography>
  )
}

export const LocationsTitle = ({
  children,
  component = 'h2',
  ...restProps
}) => {
  return (
    <Typography component={component} variant="LocationsTitle" {...restProps}>
      {children}
    </Typography>
  )
}

export const MoreFiltersButtonText = ({
  children,
  component = 'div',
  ...restProps
}) => {
  return (
    <Typography
      component={component}
      variant="MoreFiltersButtonText"
      {...restProps}
    >
      {children}
    </Typography>
  )
}

export const SubfilterTitle = ({
  children,
  component = 'h4',
  ...restProps
}) => {
  return (
    <Typography component={component} variant="SubfilterTitle" {...restProps}>
      {children}
    </Typography>
  )
}

export const CursiveParagraph = ({
  children,
  component = 'div',
  ...restProps
}) => {
  return (
    <Typography component={component} variant="CursiveParagraph" {...restProps}>
      {children}
    </Typography>
  )
}

export const InfoListTitle = ({ children, component = 'h4', ...restProps }) => {
  return (
    <Typography component={component} variant="InfoListTitle" {...restProps}>
      {children}
    </Typography>
  )
}

export const FourOhFourTitle = ({
  children,
  component = 'h2',
  ...restProps
}) => {
  return (
    <Typography component={component} variant="FourOhFourTitle" {...restProps}>
      {children}
    </Typography>
  )
}

export const SearchSiteTitle = ({
  children,
  component = 'h3',
  ...restProps
}) => {
  return (
    <Typography component={component} variant="SearchSiteTitle" {...restProps}>
      {children}
    </Typography>
  )
}

export const SearchSiteDate = ({
  children,
  component = 'div',
  ...restProps
}) => {
  return (
    <Typography component={component} variant="SearchSiteDate" {...restProps}>
      {children}
    </Typography>
  )
}

export const SearchSiteCategory = ({
  children,
  component = 'div',
  ...restProps
}) => {
  return (
    <Typography
      component={component}
      variant="SearchSiteCategory"
      {...restProps}
    >
      {children}
    </Typography>
  )
}

export const DateBlockDay = ({ children, component = 'div', ...restProps }) => {
  return (
    <Typography component={component} variant="DateDay" {...restProps}>
      {children}
    </Typography>
  )
}
export const DateBlockMonth = ({
  children,
  component = 'div',
  ...restProps
}) => {
  return (
    <Typography component={component} variant="DateMonth" {...restProps}>
      {children}
    </Typography>
  )
}

export const GreenTag = ({ children, component = 'div', ...restProps }) => {
  return (
    <Typography component={component} variant="GreenTag" {...restProps}>
      {children}
    </Typography>
  )
}

export const TabText = ({ children, component = 'div', ...restProps }) => {
  return (
    <Typography component={component} variant="TabText" {...restProps}>
      {children}
    </Typography>
  )
}

export const SchemaResultTitle = ({
  children,
  component = 'div',
  ...restProps
}) => {
  return (
    <Typography
      component={component}
      variant="SchemaResultTitle"
      {...restProps}
    >
      {children}
    </Typography>
  )
}

export const OpeningtimeTimeslot = ({
  children,
  component = 'div',
  ...restProps
}) => {
  return (
    <Typography
      component={component}
      variant="OpeningtimeTimeslot"
      {...restProps}
    >
      {children}
    </Typography>
  )
}

// Subsite
export const SubsiteHeaderTitle = ({
  children,
  component = 'h2',
  ...restProps
}) => {
  return (
    <Typography
      component={component}
      variant="SubsiteHeaderTitle"
      {...restProps}
    >
      {children}
    </Typography>
  )
}
export const SubsiteTitle = ({ children, component = 'h1', ...restProps }) => {
  return (
    <Typography component={component} variant="SubsiteTitle" {...restProps}>
      {children}
    </Typography>
  )
}
export const SubsiteCta = ({ children, component = 'div', ...restProps }) => {
  return (
    <Typography component={component} variant="SubsiteCta" {...restProps}>
      {children}
    </Typography>
  )
}

export const WeekSelector = ({ children, component = 'div', ...restProps }) => {
  return (
    <Typography component={component} variant="WeekSelector" {...restProps}>
      {children}
    </Typography>
  )
}

export const ShareText = ({ children, component = 'div', ...restProps }) => {
  return (
    <Typography component={component} variant="Share" {...restProps}>
      {children}
    </Typography>
  )
}

export const ClubDetails = ({ children, component = 'div', ...restProps }) => {
  return (
    <Typography component={component} variant="ClubDetails" {...restProps}>
      {children}
    </Typography>
  )
}

export const Caption = ({ children, component = 'div', ...restProps }) => {
  return (
    <Typography component={component} variant="Caption" {...restProps}>
      {children}
    </Typography>
  )
}

const TypographyDict = {
  title: Title,
  subtitle: Subtitle,
  buttonText: ButtonText,
  headerTitle: HeaderTitle,
  headerTitle2Rows: HeaderTitle2Rows,
  headerTitleWhite: HeaderTitleWhite,
  breadcrumb: Breadcrumb,
  p: P,
  cardText: CardText,
  blockTitle: BlockTitle,
  greenTitle: GreenTitle,
  secondaryButtonText: SecondaryButtonText,
  blankButtonText: BlankButtonText,
  iconButtonText: IconButtonText,
  newsCardTitle: NewsCardTitle,
  newsCardTitleElipsis: NewsCardTitleElipsis,
  newsCardCategory: NewsCardCategory,
  newsCardDate: NewsCardDate,
  newsCardDateWithDot: NewsCardDateWithDot,
  searchCardTitle: SearchCardTitle,
  eventBlockTitle: EventBlockTitle,
  eventBlockSubtitle: EventBlockSubtitle,
  eventDate: EventDate,
  eventDateNumber: EventDateNumber,
  eventDateMonth: EventDateMonth,
  ctaText: CTAText,
  ctaInlineText: CTAInlineText,
  faqTitle: FAQTitle,
  faqText: FAQText,
  tagText: TagText,
  facilityText: FacilityText,
  headerColumnTitle: HeaderColumnTitle,
  footerColumnTitle: FooterColumnTitle,
  footerColumnItem: FooterColumnItem,
  footerColumnItemThick: FooterColumnItemThick,
  footerText: FooterText,
  footerCopyright: FooterCopyright,
  footerInfo: FooterInfo,
  headerSubTitleWhite: HeaderSubTitleWhite,
  listTitle: ListTitle,
  moreButtonText: MoreButtonText,
  readMoreWhite: ReadMoreWhite,
  moreTitle: MoreTitle,
  subfilterTitle: SubfilterTitle,
  moreFiltersButtonText: MoreFiltersButtonText,
  cursiveParagraph: CursiveParagraph,
  locationsTitle: LocationsTitle,
  infoListTitle: InfoListTitle,
  fourOhFour: FourOhFourTitle,
  searchSiteTitle: SearchSiteTitle,
  searchSiteDate: SearchSiteDate,
  searchSiteCategory: SearchSiteCategory,
  dateDay: DateBlockDay,
  dateMonth: DateBlockMonth,
  greenTag: GreenTag,
  plusButtonText: PlusButtonText,
  tabText: TabText,
  schemaResultTitle: SchemaResultTitle,
  openingtimeTimeslot: OpeningtimeTimeslot,
  subsiteHeaderTitle: SubsiteHeaderTitle,
  subsiteTitle: SubsiteTitle,
  subsiteCta: SubsiteCta,
  weekSelector: WeekSelector,
  share: ShareText,
  clubDetails: ClubDetails,
  caption: Caption,
}

const TypographyElement = ({ variant = 'p', children, ...restProps }) => {
  if (!(variant in TypographyDict)) {
    return null
  }
  const Element = TypographyDict[variant]
  return <Element {...restProps}>{children}</Element>
}

export default TypographyElement
