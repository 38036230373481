import React from 'react'
import Image from 'next/image'

export const ImageBuilderFrontend = ({
  image,
  width = null,
  height = null,
  alt = '',
  layout = 'fill',
  decorativeOnly = false,
}) => {
  // Used for building images imported in the frontend
  if (!image) {
    return null
  }
  let dict
  if (width && height) {
    dict = { width: width, height: height }
  } else {
    dict = { layout: layout }
  }
  if (typeof image == 'string') {
    return (
      <Image
        {...dict}
        src={image}
        alt={decorativeOnly ? '' : alt}
        aria-hidden={decorativeOnly ? 'true' : 'false'}
      />
    )
  }

  if (!image.src) {
    return null
  }

  return (
    <Image
      {...dict}
      src={image.src}
      alt={decorativeOnly ? '' : alt}
      aria-hidden={decorativeOnly ? 'true' : 'false'}
      style={{ objectFit: 'cover' }}
    />
  )
}

const ImageBuilder = ({
  image,
  width = null,
  height = null,
  alt = '',
  layout,
  priority,
  decorativeOnly = false,
  maxWidth = "100%"
}) => {
  // Used for building images from the backend
  if (!image) {
    return null
  }
  if (typeof image == 'string') {
    return (
      <Image
        src={image}
        layout={layout}
        alt={decorativeOnly ? '' : alt}
        aria-hidden={decorativeOnly ? 'true' : 'false'}
        quality={75}
        priority={priority}
      />
    )
  }

  if (!image.url) {
    return null
  }
  let dict
  if (width && height) {
    dict = {
      width: width > 1000 ? 600 : width,
      height: height > 1000 ? 600 : height,
    }
  } else {
    dict = { layout: layout }
  }

  let focal = {}
  if (image?.focal && image?.focal != {}) {
    focal = {
      objectPosition: `${image?.focal?.position_y} ${image?.focal?.position_x} !important`,
      objectFit: 'cover !important',
    }
  }

  return (
    <Image
      {...dict}
      style={{
        ...focal,  // As of Nextjs14, objectPosition and objectFit must be set using style
        maxWidth
      }}
      src={image.url}
      alt={decorativeOnly ? '' : alt}
      quality={75}
      priority={priority}
      aria-hidden={decorativeOnly ? 'true' : 'false'}
    />
  )
}

export default ImageBuilder
