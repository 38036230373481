import React from 'react'
import styled from 'styled-components'

import Logo from '../../elements/logo'
import close from './assets/close.png'
import TypographyElement from '../../elements/typography'
import { ImageBuilderFrontend } from '../../elements/imageBuilder'
import CloseIcon from '@mui/icons-material/Close'
import { subsite_footer_text } from '../../colors'
import { Link } from '@mui/material'
import ButtonElement from '../../elements/buttons'

const BigMenuWrapper = styled.div`
  .backgroundFade,
  .bigMenuWrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 9999999999;
  }

  @keyframes animatefade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .backgroundFade {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    animation-name: animatefade;
    animation-duration: 1s;
  }

  @keyframes animatein {
    0% {
      left: -100%;
    }
    100% {
      left: 0;
    }
  }

  .bigMenuWrapper {
    background: ${(props) => (props.isSubsite ? '#1F486A' : '#fff')};
    width: 90%;
    max-width: 1000px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
    animation-name: animatein;
    animation-duration: 0.5s;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 100px;

    .content {
      padding: 40px;
      position: relative;

      .closeButton {
        position: absolute;
        top: 65px;
        right: 60px;
        cursor: pointer;

        color: ${(props) => (props.isSubsite ? '#fff' : '#000')};

        @media screen and (max-width: 500px) {
          span.text {
            display: none !important;
          }
        }
      }
    }

    .logoHolder {
      width: 220px;

      @media screen and (max-width: 450px) {
        padding-top: 60px;
      }
      @media screen and (max-width: 340px) {
        width: 100%;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .bigmenucontent {
    padding-top: 40px;
  }

  .linkitself a {
    padding: 4px 0;
    display: block;
    font-weight: 300;
    text-align: left;
  }

  .bigMenuItemsWrapper {
    width: 33%;
    display: inline-block;
    vertical-align: top;
    padding-right: 22px;
    margin-bottom: 40px;

    @media screen and (max-width: 800px) {
      width: 50%;
    }

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 1000px) {
    .bigmenucontent {
      padding-top: 60px;
    }
  }
`

const Wrapper = ({ title, items }) => {
  return (
    <div className="bigMenuItemsWrapper">
      <TypographyElement variant="headerColumnTitle">{title}</TypographyElement>
      <div className="bigmenulinks">
        {items &&
          items?.map((item) => (
            <div
              key={`link-big-menu-${title}-${item.text}`}
              className="linkitself"
            >
              <TypographyElement variant="footerColumnItem">
                <a href={item.href}>{item.text}</a>
              </TypographyElement>
            </div>
          ))}
      </div>
    </div>
  )
}

const BigMenuWrapperModule = ({
  isOpen,
  setIsOpen,
  data,
  contact,
  isSubsite = false,
}) => {
  // Add a ref to the modal. Used to achieve focus trapping
  const modalRef = React.useRef(null)
  React.useEffect(() => {
    if (isOpen) {
      const modalElement = modalRef.current
      // add any focusable HTML element you want to include to this string
      const focusableElements = modalElement.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      )
      const firstElement = focusableElements[0]
      const lastElement = focusableElements[focusableElements.length - 1]

      const handleTabKeyPress = (event) => {
        if (event.key === 'Tab') {
          if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault()
            lastElement.focus()
          } else if (
            !event.shiftKey &&
            document.activeElement === lastElement
          ) {
            event.preventDefault()
            firstElement.focus()
          }
        }
      }

      const handleEscapeKeyPress = (event) => {
        if (event.key === 'Escape') {
          setIsOpen(false)
        }
      }

      modalElement.addEventListener('keydown', handleTabKeyPress)
      modalElement.addEventListener('keydown', handleEscapeKeyPress)

      return () => {
        modalElement.removeEventListener('keydown', handleTabKeyPress)
        modalElement.removeEventListener('keydown', handleEscapeKeyPress)
      }
    }
  }, [isOpen, setIsOpen])

  return (
    <BigMenuWrapper isSubsite={isSubsite}>
      {isOpen && (
        <style>
          {`
                    body, html {
                        width: 100% !important;
                        height: 100% !important;
                        overflow: hidden !important;
                    }
                `}
        </style>
      )}
      {isOpen && (
        <div ref={modalRef}>
          <div className="backgroundFade"></div>
          <div className="bigMenuWrapper">
            <div className="content">
              <div className="logoHolder">
                <Link href="/">
                  {' '}
                  <Logo />{' '}
                </Link>
              </div>

              <ButtonElement
                variant="empty"
                className="closeButton"
                onClick={() => setIsOpen(false)}
              >
                <span className="text"> Sluiten </span>
                {isSubsite ? (
                  <CloseIcon sx={{ color: subsite_footer_text }} />
                ) : (
                  <ImageBuilderFrontend
                    alt="Menu sluiten"
                    image={close}
                    width={14}
                    height={14}
                  />
                )}
              </ButtonElement>

              <div className="bigmenucontent">
                {data &&
                  data?.map((item) => {
                    if (!item) {
                      return null
                    }
                    return <Wrapper key={item?.title} {...item} />
                  })}
              </div>
            </div>
          </div>
        </div>
      )}
    </BigMenuWrapper>
  )
}
export default BigMenuWrapperModule
