import {
  black,
  fifth_black,
  grey,
  primary_green,
  primary_orange,
  primary_orange_dark,
  secondary_green,
  subsite_footer_text,
  tertiary_green,
  text_blue,
  white,
} from '@/core/colors'

export const primaryLinkStyling = `
    display: inline-flex;
    padding: 15px 40px;
    color: ${white};
    border-radius: 65px;
    background-color: ${primary_orange};
    text-decoration: none !important;
    &:hover {
        background-color: ${primary_orange_dark};
        text-decoration: none !important;
    };
    &:focus {
        background-color: ${primary_orange_dark};
        text-decoration: none !important;
    };
    &:active {
        background-color: ${primary_orange_dark};
    };
    svg {
        margin-left: 24px;
    };
    @media screen and (max-width: 400px) {
        max-width: 100%;
        svg {
            margin-left: 10px;
        }
    };
`

export const subsiteHeaderLinkStyling = `
    color: ${white};
    background-color: transparent;
    text-decoration: none !important;
    &:hover {
        color: ${subsite_footer_text};
        text-decoration: none !important;
    };
    &:focus {
        color: ${subsite_footer_text};
        text-decoration: none !important;
    };
    &:active {
        color: ${subsite_footer_text};
    };
`
export const whiteLinkStyling = `
    display: inline-flex;
    padding: 15px 40px;
    color: ${white};
    border-radius: 45px;
    background-color: ${white};
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    };
    &:focus {
        text-decoration: underline;
    };
    span {
        color: ${fifth_black} !important;
    };
    svg {
        margin-left: 24px;
        path {
            fill: ${fifth_black};
        };
    };
    .MuiTypography-ButtonText {
        color: ${text_blue};
    };
`
export const arrowLinkStyling = `
    margin-left: 20px;
    display: inline-block;
    padding: 15px 40px;
    color: ${white};
    background-color: ${primary_green};
    height: 76px;
    width: 90px;
    &:hover {
        background-color: ${tertiary_green};
    };
    &:focus {
        background-color: ${tertiary_green};
        outline-color: #000 !important
    };
    &:active {
        background-color: ${tertiary_green};
    };

    .svgContainer {
        paddingTop: 10px;
        margin-left: -10px;
    };
`
export const borderLinkStyling = `
    display: block;
    padding: 42px 58px;
    background-color: transparent;
    border: 1px solid ${grey};
    position: relative;
    svg {
        position: absolute;
        right: 58px;
        top: 0;
        float: right;
        height: 100%;
        width: 24px;
        path {
            stroke: null;
            fill: ${black};
        };
    };
`

export const returnLinkStyling = `
    display: inline-flex;
    padding: 15px 40px;
    color: ${white};
    border-radius: 65px;
    background-color: ${secondary_green};
    &:hover {
        background-color: ${tertiary_green};
        text-decoration: none !important;
    };
    &:focus {
        background-color: ${tertiary_green};
        text-decoration: none !important;
        outline-color: #000 !important
    };
    &:active {
        background-color: ${tertiary_green};
    };
    svg {
        margin-right: 12px;
        width: 10px;
    };
`

export const baseLinkStyling = `
    text-decoration: none;
    color: inherit;
`

export const baseArrowLinkStyling = `
    text-decoration: none;
    display: inline-flex;
    flex-direction: row;
    gap: 43px;
    align-items: center;

    svg {
        width: 15px;
    };
`

export const innerSkewedLinkStyling = `
    text-decoration: none;
    color: inherit;
    &.Mui-focusVisible {
        outline: 0;
        .skewWrapper {
            &:before  {
                outline: -webkit-focus-ring-color auto 1px;
            }
        }
    };
`

export const linkStylingDictSubsite = {
  primary: primaryLinkStyling,
  subsiteHeaderLink: subsiteHeaderLinkStyling,
  white: whiteLinkStyling,
  arrow: arrowLinkStyling,
  border: borderLinkStyling,
  return: returnLinkStyling,
  base: baseLinkStyling,
  baseArrow: baseArrowLinkStyling,
  innerSkewed: innerSkewedLinkStyling,
}
